import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "card-custom" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item_sliding, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item_options, { side: "start" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item_option, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.closeOutline,
                    color: _ctx.secondary
                  }, null, 8, ["icon", "color"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.notification.printerName), 1),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.notification.status), 1),
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.printOutline,
                    color: "primary"
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.notification.fileName) + " ", 1),
                  _createElementVNode("p", null, [
                    _createElementVNode("small", null, _toDisplayString(_ctx.notification.message), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item_options, { side: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item_option, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.closeOutline,
                    color: _ctx.secondary
                  }, null, 8, ["icon", "color"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}