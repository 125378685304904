
import { IonIcon, IonItem, IonLabel, IonCard } from '@ionic/vue';
import { printOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrinterListItem',
  components: {
    IonIcon,
    IonItem,
    IonLabel,
    IonCard,
  },
  props: {
    printer: Object,
  },
  methods: {
    isIos: () => {
      const win = window as any;
      return win && win.Ionic && win.Ionic.mode === 'ios';
    }
  },
  data() {
    return { printOutline }
  }
});
