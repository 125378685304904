
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonList, IonToolbar } from '@ionic/vue';
import PrinterListItem from '@/components/PrinterListItem.vue';
import { defineComponent } from 'vue';
import { Storage } from '@ionic/storage';

export default defineComponent({
  name: 'AddPrinter',
  data() {
    return {
      getBackButtonText: () => {
        const win = window as any;
        const mode = win && win.Ionic && win.Ionic.mode;
        return mode === 'ios' ? 'Back' : '';
      },
      addedPrinters: null,
      otherPrinters: null,
      networkDevices: [],
    }
  },
  created() {
    this.getAddedPrinters()
    this.getOtherPrinters()
  },
  methods: {
    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete()
      }, 3000)
    },
    async getAddedPrinters () {
      const storage = new Storage()
      storage.create()
      await storage.get('printers')
        .then((printers) => {
          this.addedPrinters = printers.filter(m => m.added === true)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async getOtherPrinters () {
      const storage = new Storage()
      storage.create()
      await storage.get('printers')
        .then((printers) => {
          this.otherPrinters = printers.filter(m => m.added === false)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonList,
    PrinterListItem
  }
})
