
import { IonContent, IonHeader, IonPage, IonToolbar, IonButtons, IonLabel, IonList, IonItem, IonToggle } from '@ionic/vue';
import { settingsOutline, printOutline, listOutline, notificationsOutline, logoFacebook } from 'ionicons/icons';
import FooterToolbar from '@/components/FooterToolbar.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationsSettings',
  data() {
    return {
      toppings: [],
      settingsOutline, printOutline, listOutline, notificationsOutline, logoFacebook
    }
  },
  methods: {
    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    },
  },
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    FooterToolbar,
    IonLabel,
    IonList,
    IonItem,
    IonToggle
  },
});
