
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonGrid
} from '@ionic/vue';
import {
  addOutline,
  printOutline,
  listOutline,
  notificationsOutline,
  logoFacebook,
  logoLinkedin,
  logoInstagram,
  logoYoutube
} from 'ionicons/icons';
import PrinterListItem from '@/components/PrinterListItem.vue';
import FooterToolbar from '@/components/FooterToolbar.vue';
import { defineComponent } from 'vue';
import { Storage } from '@ionic/storage';
import axios from 'axios'

export default defineComponent({
  name: 'HomePage',
  data() {
    return {
      printers: [
        {
            id: 0,
            api: this.API_URL,
            name: 'Pragostroj',
            status: 'Offline',
            added: true
        }
      ],
      printer: {},
      addOutline,
      printOutline,
      listOutline,
      notificationsOutline,
      logoFacebook,
      logoLinkedin,
      logoInstagram,
      logoYoutube
    }
  },
  inject: [
    'API_URL',
    'printerState'
  ],
  created() {
    //this.getAddedPrinters()
    this.getPrinterData()

  },
  methods: {
    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete()
      }, 3000)
    },
    async getAddedPrinters () {
      const storage = new Storage()
      storage.create()
      await storage.get('printers')
        .then((printers) => {
          this.printers = printers.filter(m => m.added === true)
          this.getPrinterStatus()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getPrinterData () {
      try {
        axios.get(`${this.API_URL}/config/getProductInfo/`).then((response) => {
          const storage = new Storage()
          storage.create()
          this.printers[0] = {
              id: 0,
              api: this.API_URL,
              name: response.data.printerName,
              status: 'Printing',
              added: true,
              data: response.data
          }
          //storage.set('printers', this.printers)
          this.getAddedPrinters()
        }).catch(function (error) {
          console.log(error)
          alert(error)
          this.printers[0] = {
              id: 0,
              api: this.API_URL,
              name: 'Pragostroj',
              status: 'Offline',
              added: true
          }
        })
      } catch (error) {
        console.log(error)
        alert(error)
        this.printers[0] = {
            id: 0,
            api: this.API_URL,
            name: 'Pragostroj',
            status: 'Offline',
            added: true
        }
      }
    },
    async getPrinterStatus () {
      const storage = new Storage()
      storage.create()
      for (let i = 0; i < this.printers.length; i++) {
        //if (this.printers[i].api) {
          setInterval(() => {
            axios.get(`${this.API_URL}/printer/printerstatus/`).then((response) => {
              //storage.set('printer', response.data)
              this.printer = response.data
              this.printers.find(m => m.id === this.printers[i].id).status = this.printerState[response.data.machineState]
              //storage.set('printers', this.printers)
            })
          }, 5000)
        //}
      } 
    },
    
    /*
    //http://10.155.1.10:8080/
    async getPrinter (id: number) {
      return await this.storage.get('printers').find(m => m.id === id)
    },
    async getAddedPrinters () {
      return await this.storage.get('printers').filter(m => m.added === true)
    },
    async getCurrentPrinter () {
      return await this.storage.get('printers').find(m => m.id === this.storage.get('currentPrinterId'))
    },
    async getOtherPrinters () {
      return await this.storage.get('printers').filter(m => m.added === false)
    },
    async getNotifications () {
      return await this.storage.get('notifications')
    },
    async setCurrentPrinter (printer) {
      return await this.storage.set('currentPrinterId', printer.id)
    }
    */
  },
  components: {
    IonContent,
    IonHeader,
    IonList,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonGrid,
    PrinterListItem,
    FooterToolbar
  },
});
