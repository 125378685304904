
import {
  IonIcon,
  IonItem, 
  IonItemOption, 
  IonItemOptions, 
  IonItemSliding, 
  IonLabel
} from '@ionic/vue';
import { printOutline, closeOutline } from 'ionicons/icons';
import { chevronForward } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationListItem',
  components: {
    IonIcon,
    IonItem, 
    IonItemOption, 
    IonItemOptions, 
    IonItemSliding, 
    IonLabel,
  },
  props: {
    notification: Object,
  },
  methods: {
    isIos: () => {
      const win = window as any;
      return win && win.Ionic && win.Ionic.mode === 'ios';
    }
  },
  data() {
    return { printOutline, closeOutline, chevronForward }
  }
});
