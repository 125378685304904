
import { IonContent, IonHeader, IonList, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/vue';
import { settingsOutline, printOutline, listOutline, notificationsOutline, logoFacebook } from 'ionicons/icons';
import NotificationListItem from '@/components/NotificationListItem.vue';
import FooterToolbar from '@/components/FooterToolbar.vue';
import { defineComponent } from 'vue';
import { Storage } from '@ionic/storage';

export default defineComponent({
  name: 'NotificationsPage',
  data() {
    return {
      notifications: this.getNotifications(),
      settingsOutline, printOutline, listOutline, notificationsOutline, logoFacebook
    }
  },
  methods: {
    refresh: (ev: CustomEvent) => {
      setTimeout(() => {
        ev.detail.complete();
      }, 3000);
    },
    getNotifications () {
      //const storage = new Storage()
      //storage.create()

      return [
        {
            "id": 0,
            "printerId": 0,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Printing"
        },
        {
            "id": 1,
            "printerId": 0,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Available"
        },
        {
            "id": 2,
            "printerId": 0,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Offline"
        },
        {
            "id": 3,
            "printerId": 0,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Finished"
        },
        {
            "id": 4,
            "printerId": 0,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Available"
        },
        {
            "id": 5,
            "printerId": 0,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Warning"
        },
        {
            "id": 6,
            "printerId": 1,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Offline"
        },
        {
            "id": 7,
            "printerId": 2,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Offline"
        },
        {
            "id": 8,
            "printerId": 3,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Offline"
        },
        {
            "id": 9,
            "printerId": 4,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Offline"
        },
        {
            "id": 10,
            "printerId": 5,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Offline"
        },
        {
            "id": 11,
            "printerId": 6,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Offline"
        },
        {
            "id": 12,
            "printerId": 7,
            "fileName": "File name",
            "message": "Print finished DD.MM. at HH:MM.",
            "status": "Offline"
        }
    ]
      /*
      await storage.get('notifications')
        .then((notifications) => {
          this.notifications = notifications
          console.log(notifications)
        })
        .catch((error) => {
          console.log(error)
        })
        */
    },
    clearNotifications () {
      const storage = new Storage()
      storage.create()
      this.notifications = []
      //storage.set('notifications', [])
    }
  },
  components: {
    IonContent,
    IonHeader,
    IonList,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    NotificationListItem,
    FooterToolbar
  },
});
