import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_ctx.printer)
    ? (_openBlock(), _createBlock(_component_ion_card, {
        key: 0,
        class: _normalizeClass(["card-custom", (_ctx.printer.status === 'Offline' ? 'disabled' : '')])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, {
            routerLink: '/printer/' + _ctx.printer.id,
            detail: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.printOutline,
                size: "large",
                color: "primary"
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.printer.name), 1),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.printer.status), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["routerLink"])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}