
import { IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, IonBadge } from '@ionic/vue';
import { addOutline, printOutline, listOutline, notificationsOutline, logoFacebook } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterToolbar',
  data() {
    return {
      addOutline, printOutline, listOutline, notificationsOutline, logoFacebook
    }
  },
  components: {
    IonFooter,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonBadge
  },
});
