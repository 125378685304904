import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import CurrentPrinter from '../views/CurrentPrinter.vue'
import NotificationsPage from '../views/Notifications.vue'
import NotificationsSettings from '../views/NotificationsSettings.vue'
import AddPrinter from '../views/AddPrinter.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/current',
    name: 'CurrentPrinter',
    component: CurrentPrinter
  },
  {
    path: '/notifications',
    name: 'NotificationsPage',
    component: NotificationsPage
  },
  {
    path: '/notifications-settings',
    name: 'NotificationsSettings',
    component: NotificationsSettings
  },
  {
    path: '/add-printer',
    name: 'AddPrinter',
    component: AddPrinter
  },
  {
    path: '/printer/:id',
    component: () => import('../views/ViewPrinter.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
