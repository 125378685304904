import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { IonicVue } from '@ionic/vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme styles */
import './theme/variables.css'
import './theme/style.css'

import { defineCustomElements } from '@ionic/pwa-elements/loader'

const app = createApp(App)
  .use(IonicVue)
  .use(router)

  app.provide('API_URL', '/api/v1')
  //app.provide('API_URL', 'http://192.168.20.89:8080/api/v1')
  app.provide('printerState', {
    B: 'Busy', 
    C: 'Configuration',
    F: 'Firmware upgrade',
    I: 'Idle',
    A: 'Printing paused',
    D: 'Pausing print',
    E: 'Preparing to print',
    P: 'Printing',
    R: 'Resuming print',
    S: 'Emergency stop',
    T: 'Switching extruder'})

/*    
app.provide('storage', new Storage())
await this.storage.create()

await this.storage.set('currentPrinterId', 0)
await this.storage.set('printers', [
  { id: 0, name: 'Tiskárna Maruška', status: 'Printing', added: true },
  { id: 1, name: 'Tiskárna Hanička', status: 'Available', added: true },
  { id: 2, name: 'Tiskárna Terezka', status: 'Offline', added: true },
  { id: 3, name: 'Tiskárna Anička', status: 'Finished', added: true },
  { id: 4, name: 'Tiskárna Evička', status: 'Available', added: false },
  { id: 5, name: 'Tiskárna Libuška', status: 'Warning', added: false },
  { id: 6, name: 'Tiskárna Prago 1', status: 'Offline', added: false },
  { id: 7, name: 'Tiskárna Prago 2', status: 'Offline', added: false },
  { id: 8, name: 'Tiskárna Prago 3', status: 'Offline', added: false },
  { id: 9, name: 'Tiskárna Prago 4', status: 'Offline', added: false },
  { id: 10, name: 'Tiskárna Prago 5', status: 'Offline', added: false },
  { id: 11, name: 'Tiskárna Prago 6', status: 'Offline', added: false },
  { id: 12, name: 'Tiskárna Prago 7', status: 'Offline', added: false }
])
await this.storage.set('notifications', [
  { id: 0, printerId: 0, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Printing' },
  { id: 1, printerId: 0, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Available' },
  { id: 2, printerId: 0, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Offline' },
  { id: 3, printerId: 0, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Finished' },
  { id: 4, printerId: 0, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Available' },
  { id: 5, printerId: 0, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Warning' },
  { id: 6, printerId: 1, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Offline' },
  { id: 7, printerId: 2, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Offline' },
  { id: 8, printerId: 3, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Offline' },
  { id: 9, printerId: 4, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Offline' },
  { id: 10, printerId: 5, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Offline' },
  { id: 11, printerId: 6, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Offline' },
  { id: 12, printerId: 7, fileName: 'File name', message: 'Print finished DD.MM. at HH:MM.', status: 'Offline' }
])
*/

defineCustomElements(window)

router.isReady().then(() => {
  app.mount('#app')
})

