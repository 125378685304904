
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { defineComponent } from 'vue'
import { Storage } from '@ionic/storage'

export default defineComponent({
  name: 'App',
  created() {
    /*
    getUser(dataRef.instance.value.id)
    .then((data) => {
      storage.set('printers', { id: 0, name: 'Tiskárna Maruška', status: 'Printing', added: true })
    })
    */
    
  },
  data() {
    return {
      ws: null
    }
  },
  components: {
    IonApp,
    IonRouterOutlet
  }
})
